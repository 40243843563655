import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DomainService {
  readonly siteHost = process.env['SITE_URL']!.replace(/(^\w+:|^)\/\//, '') // remove protocol
    .replace(/(:.*)$/, ''); // remove port (only important for dev)

  getCustomSubdomain() {
    const currentHost = location.hostname;
    if (
      currentHost.endsWith(this.siteHost) &&
      currentHost.length > this.siteHost.length
    ) {
      return currentHost.slice(0, -this.siteHost.length - 1);
    } else {
      return undefined;
    }
  }

  getLogoUrl() {
    const customSubDomain = this.getCustomSubdomain();
    return customSubDomain
      ? `${process.env['BUCKET_URL']}/domain-logos/${customSubDomain}.png`
      : undefined;
  }
}
