import { Injectable } from '@angular/core';
import { DataProxy } from '@apollo/client/core';

import {
  CreateOrgGQL,
  CreateOrgMutationVariables,
  DeleteUserGQL,
  DeleteUserMutationVariables,
  InviteUserGQL,
  InviteUserMutationVariables,
  OrgAdminDetailsFragment,
  OrgsGQL,
  OrgsQuery,
  ResendUserInviteGQL,
  ResendUserInviteMutationVariables,
  UpdateOrgGQL,
  UpdateOrgMutationVariables,
  UpdateUserGQL,
  UpdateUserMutationVariables,
  UserAdminDetailsFragment,
  UsersGQL,
  UsersQuery,
} from '../graphql';

@Injectable({
  providedIn: 'root',
})
export class UserAdminService {
  constructor(
    private orgsGQL: OrgsGQL,
    private createOrgGQL: CreateOrgGQL,
    private deleteUserGQL: DeleteUserGQL,
    private inviteUserGQL: InviteUserGQL,
    private resendUserInviteGQL: ResendUserInviteGQL,
    private updateOrgGQL: UpdateOrgGQL,
    private updateUserGQL: UpdateUserGQL,
    private usersGQL: UsersGQL,
  ) {}

  orgs() {
    return this.orgsGQL.watch().valueChanges;
  }

  users() {
    return this.usersGQL.watch().valueChanges;
  }

  createOrg(org: CreateOrgMutationVariables['org']) {
    return this.createOrgGQL.mutate(
      { org },
      {
        update: (proxy, { data }) => {
          if (data) {
            this.updateStoreWithNewOrg(proxy, data.createOrg);
          }
        },
      },
    );
  }

  updateOrg(org: UpdateOrgMutationVariables['org']) {
    return this.updateOrgGQL.mutate({ org });
  }

  inviteUser(variables: InviteUserMutationVariables) {
    return this.inviteUserGQL.mutate(variables, {
      update: (proxy, { data }) => {
        if (data) {
          this.updateStoreWithNewUser(proxy, data.inviteUser);
        }
      },
      refetchQueries: [{ query: this.orgsGQL.document }],
    });
  }

  resendUserInvite(variables: ResendUserInviteMutationVariables) {
    return this.resendUserInviteGQL.mutate(variables);
  }

  updateUser(variables: UpdateUserMutationVariables) {
    return this.updateUserGQL.mutate(variables, {
      refetchQueries: [{ query: this.orgsGQL.document }],
    });
  }

  deleteUser(variables: DeleteUserMutationVariables) {
    return this.deleteUserGQL.mutate(variables, {
      update: (proxy, { data }) => {
        if (data) {
          this.deleteUserFromStore(proxy, data.deleteUser.id);
        }
      },
    });
  }

  private updateStoreWithNewUser(
    proxy: DataProxy,
    user: UserAdminDetailsFragment,
  ): void {
    const query = this.usersGQL.document;
    const data = proxy.readQuery<UsersQuery>({ query });
    if (data) {
      proxy.writeQuery({
        query,
        data: {
          users: [...data.users, user],
        },
      });
    }
  }

  private deleteUserFromStore(proxy: DataProxy, userId: string): void {
    const query = this.usersGQL.document;
    const data = proxy.readQuery<UsersQuery>({ query });
    if (data) {
      proxy.writeQuery({
        query,
        data: {
          users: data.users.filter((user) => user.id !== userId),
        },
      });
    }
  }

  private updateStoreWithNewOrg(
    proxy: DataProxy,
    org: OrgAdminDetailsFragment,
  ): void {
    const query = this.orgsGQL.document;
    try {
      const data = proxy.readQuery<OrgsQuery>({ query });

      if (data) {
        proxy.writeQuery({
          query,
          data: {
            orgs: [...data.orgs, org],
          },
        });
      }
    } catch (error) {
      // error can occur if shop owner groups query hasn't been run yet, which can be ignored safely
    }
  }
}
